@import '../../../variables.scss';
.privateNavigation {
	display: flex;
    justify-content: space-between;
	align-items: center;
	height: 64px;
	.navLink {
		display: flex;
		align-items: center;
		color: #ffffff;
		    gap: 5px; // Space between icon and text

		b {
			font-weight: normal;
		}
		&.selected {
			z-index: 1;
			border-bottom: 2px solid $primaryColorLight;
			color: #ffffff;
			b {
				font-weight: 700;
				color: $primaryColorLight;
			}
			i {
				color: $primaryColorLight;
			}
		}
	}
	.navLogo {
		a {
			display: flex;
			align-items: center;
			font-size: 25px;
			font-family: impact;
			letter-spacing: 2px;
			color: #A1C575;
			.navLogoImg {
				width: 45px;
				height: 45px;
			}
		}
	}
	.navCenter {
		display: flex;
		gap: 35px;
		align-items: center;
		justify-content: center;
		margin-right: auto;
		margin-left: auto;
	}
	.navProfile {
		cursor: pointer;
		display: flex;
		height: 100%;
		align-items: center;
		background: $primaryColor;
		padding: 15px;
		.navProfileCta {
			display: flex;
			align-items: center;
			gap: 10px;
			.ant-typography {
				color: $blackTextColor;
			}
		}
	}
	.privateMobileNavItem {
		display: none;
		background: $primaryColorLight;
		padding: 0 15px;
		cursor: pointer;
		i {
			color: #ffffff;
		}
	}
	i {
		color: #ffffff;
		padding-right: 8px;
	  }
	  
	  b {
		color: #ffffff;
	  }
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.navLink {
			b {
				display: none;
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.navLink {
			b {
				display: none;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.navLink {
			b {
				display: none;
			}
		}
	}

	@media all and (max-width: 480px) {
		.privateMobileNavItem {
			display: flex;
		}
		.navLogo {
			padding-left: 25px;
		}
		.navCenter {
			display: none;
		}
		.navProfile {
			display: none;
		}
	}
}
.mobileActions {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-right: 16px;
	
	.messageIcon {
	  cursor: pointer;
	  i {
		font-size: 28px;
		color: white;
	  }
	}
  
	.menuIcon {
	  cursor: pointer;
	  i {
		font-size: 32px;
		color: white;
	  }
	}
  
	.ant-badge {
	  .ant-badge-count {
		background: #ff4d4f;
		min-width: 20px;
		height: 20px;
		border-radius: 10px;
		padding: 0 6px;
		font-size: 12px;
		line-height: 20px;
		box-shadow: 0 0 0 1px #333333;
		z-index: 10;
	  }
	}
  }

  .mobileActions {
  display: none; // Hide by default on desktop

  @media (max-width: 768px) {
    display: flex; // Only show on mobile screens
    align-items: center;
  }
}

// Make sure the main navigation is visible on desktop but hidden on mobile
.navCenter {
  display: flex;
  
  @media (max-width: 768px) {
    display: none; // Hide on mobile
  }
}

// Similarly for the profile dropdown
.navProfile {
  display: flex;
  
  @media (max-width: 768px) {
    display: none; // Hide on mobile
  }
}